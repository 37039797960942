import storage from "./storage";
import jwtFilter from "../filters/jwt";
import ctx from "@/ctx";

const checkForError = response => {
  if (!response.ok) {
    if (response.status === 401) {
      storage.clear();
      return location.replace(location.origin + "/login");
    } else {
      response.json().then(res => {
        if (res.message) {
          ctx.system.msg = `[Error]: ${res.message}`;
        }
      });
    }
    throw Error(response.statusText);
  }
  return response;
};

const processingResponse = response => {
  const header = response.headers.get("Content-Type");
  const type = header ? header.split(";")[0] : null;
  switch (type) {
    case "application/json":
      return response.json();
    case "text/plain":
      return response.text();
    case "application/xls" || "application/doc" || "application/pdf": {
      const disposition = response.headers.get("Content-Disposition");
      let filename = disposition.split(";")[1].split("=")[1];
      return {
        filename: filename.includes("UTF-8")
          ? decodeURIComponent(filename.slice(7))
          : filename,
        blob: response.blob()
      };
    }
    default:
      return true;
  }
};

const processingCatch = error => {
  console.log("error", error);
};

async function Get(path) {
  const jwt = await jwtFilter();
  const baseUrl = process.env.VUE_APP_BE;
  return await fetch(`${baseUrl}${path}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + jwt
    }
  })
    .then(checkForError)
    .then(processingResponse)
    .catch(processingCatch);
}

async function Post(path, payload) {
  const jwt = await jwtFilter();
  const baseUrl = process.env.VUE_APP_BE;
  return await fetch(`${baseUrl}${path}`, {
    method: "POST",
    mode: "cors",
    body:
      payload === null
        ? null
        : typeof payload === "object"
        ? JSON.stringify(payload)
        : payload,
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + jwt
    }
  })
    .then(checkForError)
    .then(processingResponse)
    .catch(processingCatch);
}

async function Delete(path) {
  const jwt = await jwtFilter();
  const baseUrl = process.env.VUE_APP_BE;
  return await fetch(`${baseUrl}${path}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + jwt
    }
  })
    .then(checkForError)
    .then(processingResponse)
    .catch(processingCatch);
}

async function Upload(path, file) {
  const jwt = await jwtFilter();
  const baseUrl = process.env.VUE_APP_BE;
  let formData = new FormData();
  formData.append("file", file);
  return await fetch(`${baseUrl}${path}`, {
    method: "POST",
    mode: "cors",
    body: formData,
    headers: {
      Authorization: "Bearer " + jwt
    }
  })
    .then(checkForError)
    .then(processingResponse)
    .catch(processingCatch);
}


async function octellIntegrationApi(path, operatorID) {
  // const baseUrl = process.env.VUE_APP_BE;
  const baseUrlDev = `https://mdb.sosmed.kz/v1.0`;
  const response = await fetch(`${baseUrlDev}${path}`, {
    method: "POST",
    headers: {
      // "Authorization": '9d9ceeef6957d77e15284f8f4c1ba7bf',
      "Content-Type": "application/json", // Добавлен заголовок Content-Type
    },
    body: JSON.stringify({
      operatorId: operatorID,
      secretKey: "6FvoQEN63SDOhosPKoTif0jwbYlrMnjOJJdK9J9BgwzdFk6X3jAS3fWdRrbdqXEPYZ11fMinPt5EJmUbDqowaCNjBFlbFbZKmIc4o8IlPXcqv1E4aT6jFyDBG26B5Ghl",
    }),
  });

  // Возвращаем результат асинхронного вызова
  return await response.json();
}

export default {
  get: async path => {
    return await Get(path);
  },
  post: async (path, payload) => {
    return await Post(path, payload);
  },
  upload: async (path, file) => {
    return await Upload(path, file);
  },
  octell: async (path, operatorID) => {
    return await octellIntegrationApi(path, operatorID);
  },
  delete: async (path) => {
    return await Delete(path)
  }
};
